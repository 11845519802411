import React, { Component } from 'react';

class PersonalityComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      component: null,
    };
  }

  componentDidMount() {
    // Load the component dynamically based on the personality prop
    import(`./${this.props.personality}`).then((module) => {
      this.setState({ component: module.default });
    });
  }

  render() {
    const { component: Component } = this.state;

    if (Component) {
      // Render the loaded component
      return <Component />;
    } else {
      // You can render a loading message or any other fallback content here
      return <div>Loading...</div>;
    }
  }
}

export default PersonalityComponent;
