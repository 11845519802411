import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Container,
  FormControlLabel,
  LinearProgress,
  Modal,
  Radio,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import logo from "./logo.png";
import { useNavigate } from "react-router-dom";
import { useConfetti } from "./confettiContext";

const Form = ({ children }) => {
  const { setShowConfetti } = useConfetti();

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const questions = [
    {
      id: 1,
      question: "Para você, é mais difícil",
      statement1: "identificar-se com os outros",
      statement2: "utilizar os outros",
      type1: "E",
      type2: "I",
    },
    {
      id: 2,
      question: "Você prefere",
      statement1: "muitos amigos com contato breve",
      statement2: "poucos amigos com contato mais prolongado",
      type1: "S",
      type2: "N",
    },
    {
      id: 3,
      question: "Você diria que é mais",
      statement1: "Sério e determinado",
      statement2: "Descontraído",
      type1: "S",
      type2: "N",
    },
    {
      id: 4,
      question: "Você tende a buscar",
      statement1: "o ordenado",
      statement2: "o que aparece",
      type1: "T",
      type2: "F",
    },
    {
      id: 5,
      question: "É preferível, na maioria das vezes,",
      statement1: "garantir que as coisas estejam organizadas",
      statement2: "deixar as coisas acontecerem",
      type1: "T",
      type2: "F",
    },
    {
      id: 6,
      question: "Você prefere o",
      statement1: "evento planejado",
      statement2: "evento não planejado",
      type1: "J",
      type2: "P",
    },
    {
      id: 7,
      question: "As crianças frequentemente não",
      statement1: "se tornam úteis o suficiente",
      statement2: "exercitam sua fantasia o suficiente",
      type1: "J",
      type2: "P",
    },
    {
      id: 8,
      question: "Você fica mais impressionado com",
      statement1: "Princípios",
      statement2: "Emoções",
      type1: "E",
      type2: "I",
    },
    {
      id: 9,
      question: "Você é uma pessoa que é mais",
      statement1: "rotineira do que impulsiva",
      statement2: "impulsiva do que rotineira",
      type1: "S",
      type2: "N",
    },
    {
      id: 10,
      question: "Com mais frequência, você é",
      statement1: "uma pessoa de cabeça fria",
      statement2: "uma pessoa de coração caloroso",
      type1: "S",
      type2: "N",
    },
    {
      id: 11,
      question: "O que é mais satisfatório:",
      statement1: "discutir um problema profundamente",
      statement2: "chegar a um acordo sobre um problema",
      type1: "T",
      type2: "F",
    },
    {
      id: 12,
      question: "Fatos:",
      statement1: "'Falam por si mesmos'",
      statement2: "Ilustram princípios",
      type1: "T",
      type2: "F",
    },
    {
      id: 13,
      question: "Qual é o maior defeito:",
      statement1: "ser indiscriminado",
      statement2: "ser crítico",
      type1: "J",
      type2: "P",
    },
    {
      id: 14,
      question:
        "Ao se aproximar dos outros, você tem inclinação para ser um pouco mais",
      statement1: "Objetivo",
      statement2: "Pessoal",
      type1: "J",
      type2: "P",
    },
    {
      id: 15,
      question: "Ao julgar os outros, você é mais influenciado por",
      statement1: "Leis do que circunstâncias",
      statement2: "Circunstâncias do que leis",
      type1: "E",
      type2: "I",
    },
    {
      id: 16,
      question: "Com mais frequência, você é uma pessoa mais",
      statement1: "prática",
      statement2: "fantasiosa",
      type1: "S",
      type2: "N",
    },
    {
      id: 17,
      question: "Visionários são:",
      statement1: "um tanto irritantes",
      statement2: "bastante fascinantes",
      type1: "S",
      type2: "N",
    },
    {
      id: 18,
      question: "Você prefere mais a",
      statement1: "declaração final e imutável",
      statement2: "declaração provisória e preliminar",
      type1: "T",
      type2: "F",
    },
    {
      id: 19,
      question: "Na companhia de outros, você:",
      statement1: "inicia a conversa",
      statement2: "espera ser abordado",
      type1: "T",
      type2: "F",
    },
    {
      id: 20,
      question: "Qual situação te atrai mais:",
      statement1: "a estruturada e programada",
      statement2: "a não estruturada e não programada",
      type1: "J",
      type2: "P",
    },
    {
      id: 21,
      question: "Você se sente mais confortável em fazer:",
      statement1: "Julgamentos lógicos",
      statement2: "Julgamentos de valor",
      type1: "J",
      type2: "P",
    },
    {
      id: 22,
      question: "Em uma festa, você:",
      statement1: "interage com muitos, incluindo estranhos",
      statement2: "interage com alguns, conhecidos por você",
      type1: "E",
      type2: "I",
    },
    {
      id: 23,
      question: "Você se sente mais confortável com o trabalho que é:",
      statement1: "contratado",
      statement2: "feito de forma casual",
      type1: "S",
      type2: "N",
    },
    {
      id: 24,
      question: "Você valoriza mais em si mesmo o fato de ser:",
      statement1: "inabalável",
      statement2: "devotado",
      type1: "S",
      type2: "N",
    },
    {
      id: 25,
      question: "Normalmente, você deixa os eventos acontecerem:",
      statement1: "por seleção e escolha cuidadosas",
      statement2: "aleatoriamente e ao acaso",
      type1: "T",
      type2: "F",
    },
    {
      id: 26,
      question: "Você quer as coisas:",
      statement1: "resolvidas e decididas",
      statement2: "indefinidas e não decididas",
      type1: "T",
      type2: "F",
    },
    {
      id: 27,
      question: "Você está mais interessado em:",
      statement1: "produção e distribuição",
      statement2: "design e pesquisa",
      type1: "J",
      type2: "P",
    },
    {
      id: 28,
      question: "Você é mais:",
      statement1: "pontual",
      statement2: "relaxado",
      type1: "J",
      type2: "P",
    },
    {
      id: 29,
      question: "Em seus grupos sociais, você:",
      statement1: "acompanha o que está acontecendo com os outros",
      statement2: "fica por fora das novidades",
      type1: "E",
      type2: "I",
    },
    {
      id: 30,
      question: "O que te atrai mais:",
      statement1: "Coerência de pensamento",
      statement2: "Relações humanas harmoniosas",
      type1: "S",
      type2: "N",
    },
    {
      id: 31,
      question: "Você prefere trabalhar:",
      statement1: "com prazos",
      statement2: "apenas 'quando der'",
      type1: "S",
      type2: "N",
    },
    {
      id: 32,
      question: "Você é mais atraído por:",
      statement1: "fundamentos",
      statement2: "sutilezas",
      type1: "T",
      type2: "F",
    },
    {
      id: 33,
      question: "Qual é mais um elogio:",
      statement1: "'É uma pessoa muito lógica.'",
      statement2: "'É uma pessoa muito sentimental.'",
      type1: "T",
      type2: "F",
    },
    {
      id: 34,
      question: "Você se sente:",
      statement1: "mais prático do que engenhoso",
      statement2: "mais engenhoso do que prático",
      type1: "J",
      type2: "P",
    },
    {
      id: 35,
      question: "Você é mais atraído por:",
      statement1: "Pessoas sensatas",
      statement2: "Pessoas imaginativas",
      type1: "J",
      type2: "P",
    },
    {
      id: 36,
      question: "Te incomoda mais ter as coisas:",
      statement1: "incompletas",
      statement2: "completas",
      type1: "E",
      type2: "I",
    },
    {
      id: 37,
      question: "Você é mais atraído por:",
      statement1: "Argumentos convincentes",
      statement2: "Elementos emocionais",
      type1: "S",
      type2: "N",
    },
    {
      id: 38,
      question: "Você se sente mais confortável:",
      statement1: "depois de tomar uma decisão",
      statement2: "antes de tomar uma decisão",
      type1: "S",
      type2: "N",
    },
    {
      id: 39,
      question: "Ao fazer coisas comuns, você é mais propenso a:",
      statement1: "Fazer do jeito usual",
      statement2: "Fazer do seu jeito",
      type1: "T",
      type2: "F",
    },
    {
      id: 40,
      question: "Em festas, você:",
      statement1: "fica até tarde, com energia crescente",
      statement2: "sai cedo com energia diminuindo",
      type1: "T",
      type2: "F",
    },
    {
      id: 41,
      question: "Você é mais propenso a:",
      statement1: "ver como os outros são úteis",
      statement2: "ver como os outros enxergam",
      type1: "J",
      type2: "P",
    },
    {
      id: 42,
      question: "O senso comum é:",
      statement1: "raramente questionável",
      statement2: "frequentemente questionável",
      type1: "J",
      type2: "P",
    },
    {
      id: 43,
      question: "O que você deseja mais para si mesmo:",
      statement1: "clareza de raciocínio",
      statement2: "força de compaixão",
      type1: "E",
      type2: "I",
    },
    {
      id: 44,
      question: "Você é mais propenso a confiar em sua:",
      statement1: "experiência",
      statement2: "intuição",
      type1: "S",
      type2: "N",
    },
    {
      id: 45,
      question: "Você é mais inclinado a ser:",
      statement1: "fácil de abordar",
      statement2: "um pouco reservado",
      type1: "S",
      type2: "N",
    },
    {
      id: 46,
      question: "Escritores devem:",
      statement1: "'Dizer o que querem dizer e querer dizer o que dizem'",
      statement2: "Expressar as coisas mais por meio de analogias",
      type1: "T",
      type2: "F",
    },
    {
      id: 47,
      question: "Ao tomar decisões, você se sente mais confortável com:",
      statement1: "padrões",
      statement2: "sentimentos",
      type1: "T",
      type2: "F",
    },
    {
      id: 48,
      question: "A interação nova e não rotineira com os outros:",
      statement1: "estimula e energiza você",
      statement2: "taxa suas reservas",
      type1: "J",
      type2: "P",
    },
    {
      id: 49,
      question: "É pior:",
      statement1: "ter a 'cabeça nas nuvens'",
      statement2: "estar 'na mesmice'",
      type1: "J",
      type2: "P",
    },
    {
      id: 50,
      question: "Qual é o erro maior:",
      statement1: "ser excessivamente apaixonado",
      statement2: "ser excessivamente objetivo",
      type1: "E",
      type2: "I",
    },
    {
      id: 51,
      question: "Você se sente melhor sobre:",
      statement1: "ter comprado",
      statement2: "ter a opção de comprar",
      type1: "S",
      type2: "N",
    },
    {
      id: 52,
      question: "Você é mais propenso a ser:",
      statement1: "justo",
      statement2: "compassivo",
      type1: "S",
      type2: "N",
    },
    {
      id: 53,
      question: "Você é mais:",
      statement1: "realista do que especulativo",
      statement2: "especulativo do que realista",
      type1: "T",
      type2: "F",
    },
    {
      id: 54,
      question: "O que é mais admirável:",
      statement1: "a capacidade de organizar e ser metódico",
      statement2: "a capacidade de se adaptar e fazer o necessário",
      type1: "T",
      type2: "F",
    },
    {
      id: 55,
      question: "É pior ser:",
      statement1: "injusto",
      statement2: "implacável",
      type1: "J",
      type2: "P",
    },
    {
      id: 56,
      question: "Quando o telefone toca, você:",
      statement1: "se apressa para atender primeiro",
      statement2: "espera que outra pessoa atenda",
      type1: "J",
      type2: "P",
    },
    {
      id: 57,
      question: "Na escrita, você prefere:",
      statement1: "o mais literal",
      statement2: "o mais figurativo",
      type1: "E",
      type2: "I",
    },
    {
      id: 58,
      question: "Você tende a escolher:",
      statement1: "bastante cuidadosamente",
      statement2: "um pouco impulsivamente",
      type1: "S",
      type2: "N",
    },
    {
      id: 59,
      question: "Você é mais propenso a ser:",
      statement1: "deliberado do que espontâneo",
      statement2: "espontâneo do que deliberado",
      type1: "S",
      type2: "N",
    },
    {
      id: 60,
      question: "Ao fazer ligações, você:",
      statement1: "raramente questiona que tudo será dito",
      statement2: "ensaiar o que vai dizer",
      type1: "T",
      type2: "F",
    },
    {
      id: 61,
      question: "Você:",
      statement1: "fala facilmente e muito com estranhos",
      statement2: "tem pouco a dizer para estranhos",
      type1: "T",
      type2: "F",
    },
    {
      id: 62,
      question: "Qual pessoa é mais digna de elogio - alguém de:",
      statement1: "raciocínio claro",
      statement2: "sentimentos fortes",
      type1: "J",
      type2: "P",
    },
    {
      id: 63,
      question: "Você se vê basicamente como:",
      statement1: "durão",
      statement2: "de coração mole",
      type1: "J",
      type2: "P",
    },
    {
      id: 64,
      question: "Você se guia mais por:",
      statement1: "fatos",
      statement2: "princípios",
      type1: "E",
      type2: "I",
    },
    {
      id: 65,
      question: "Você é mais:",
      statement1: "firme do que gentil",
      statement2: "gentil do que firme",
      type1: "S",
      type2: "N",
    },
    {
      id: 66,
      question: "Você valoriza mais em si mesmo:",
      statement1: "um forte senso de realidade",
      statement2: "uma imaginação vívida",
      type1: "S",
      type2: "N",
    },
    {
      id: 67,
      question: "Nos relacionamentos, a maioria das coisas deve ser:",
      statement1: "renegociável",
      statement2: "aleatória e circunstancial",
      type1: "T",
      type2: "F",
    },
    {
      id: 68,
      question: "O que te guia mais:",
      statement1: "sua cabeça",
      statement2: "seu coração",
      type1: "T",
      type2: "F",
    },
    {
      id: 69,
      question: "Você está mais interessado em:",
      statement1: "O que é real",
      statement2: "O que é possível",
      type1: "J",
      type2: "P",
    },
    {
      id: 70,
      question: "Você valoriza mais:",
      statement1: "o infinito",
      statement2: "a mente aberta",
      type1: "J",
      type2: "P",
    },
  ];

  const chunkSize = 5;
  const paginatedQuestions = questions.slice(
    page * chunkSize,
    (page + 1) * chunkSize
  );
  const [answers, setAnswers] = useState({});
  const allQuestionsAnswered =
    Object.values(answers).length === questions.length;
  const progress = (Object.keys(answers).length / questions.length) * 100;

  useMemo(() => {
    let result = [];
    for (let i = 0; i < questions.length; i += chunkSize) {
      const chunk = questions.slice(i, i + chunkSize);
      result.push(chunk);
    }
  }, []);

  const handleNext = () => {
    if (page < Math.ceil(questions.length / chunkSize) - 1) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const handleAnswerChange = (questionId, value) => {
    setAnswers({ ...answers, [questionId]: value });
  };

  const handleRandomAnswers = () => {
    const randomAnswers = {};
    questions.forEach((q) => {
      const randomValue = Math.random() < 0.5 ? "1" : "2"; // Randomly select 1 or 2
      randomAnswers[q.id] = randomValue;
    });
    setAnswers(randomAnswers);
    setPage(13);
  };

  function calculateMBTIResult() {
    // if (answers.length !== 70) {
    //   throw new Error('Invalid number of answers. There should be 70 answers.');
    // }
    const answerArray = Object.values(answers);

    const a = answerArray.slice(0, 35);
    const b = answerArray.slice(35, 70);

    const I = a[0] + a[7] + a[14] + a[21] + a[28] + a[35];
    const E = b[0] + b[7] + b[14] + b[21] + b[28] + b[35];
    const S =
      a[1] +
      a[8] +
      a[15] +
      a[22] +
      a[29] +
      a[36] +
      a[2] +
      a[9] +
      a[16] +
      a[23] +
      a[30] +
      a[37];
    const N =
      b[1] +
      b[8] +
      b[15] +
      b[22] +
      b[29] +
      b[36] +
      b[2] +
      b[9] +
      b[16] +
      b[23] +
      b[30] +
      b[37];
    const T =
      a[3] +
      a[10] +
      a[17] +
      a[24] +
      a[31] +
      a[38] +
      a[4] +
      a[11] +
      a[18] +
      a[25] +
      a[32] +
      a[39];
    const F =
      b[3] +
      b[10] +
      b[17] +
      b[24] +
      b[31] +
      b[38] +
      b[4] +
      b[11] +
      b[18] +
      b[25] +
      b[32] +
      b[39];
    const J =
      a[5] +
      a[12] +
      a[19] +
      a[26] +
      a[33] +
      a[40] +
      a[6] +
      a[13] +
      a[20] +
      a[27] +
      a[34] +
      a[41];
    const P =
      b[5] +
      b[12] +
      b[19] +
      b[26] +
      b[33] +
      b[40] +
      b[6] +
      b[13] +
      b[20] +
      b[27] +
      b[34] +
      b[41];

    const resultStr =
      (I > E ? "I" : "E") +
      (S > N ? "S" : "N") +
      (T > F ? "T" : "F") +
      (J > P ? "J" : "P");
    console.log(resultStr);
    setShowConfetti(true);
    navigate(`/result/${resultStr.toLocaleLowerCase()}`);

    setTimeout(() => {
      setShowConfetti(false);
    }, 1000);

    return resultStr;
  }
  
  

  return (
    <>
      <h2 style={{ textAlign: "center" }}>
        Teste Myer Briggs Type Indicator (MBTI)
      </h2>
      <LinearProgress
        sx={{
          marginBottom: "30px",
          height: "25px",
          borderRadius: "3px",
          backgroundColor: "#f8f4ec !important",

          "&.MuiLinearProgress-bar": {
            backgroundColor: "transparent",
          },
          "& .MuiLinearProgress-barColorPrimary": {
            backgroundColor: "#e8c061 !important",
            background: `linear-gradient(90deg, #e8c061 ${progress}%, transparent 0%)`,
          },
          "&.MuiLinearProgress-bar1Dashed": {
            border: "none",
          },
          "&.MuiLinearProgress-bar1:before": {
            backgroundColor: "#e8c061",
          },
          "&.MuiLinearProgress-bar1Buffer": {
            background: "transparent",
          },
          "&.MuiLinearProgress-colorPrimary:before": {
            content: `'${progress.toFixed(0)}%'`,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "blck",
          },
        }}
        variant="determinate"
        value={progress}
      ></LinearProgress>

      <form method="post" id="mbti">
        <input type="hidden" id="page" value={page} />

        <div>
          {paginatedQuestions.map((q, i) => (
            <div key={q.id} style={{ marginBottom: "16px" }}>
              <Typography variant="h5" style={{ fontWeight: "bold" }}>
                {q.question}
              </Typography>
              <div>
                <FormControlLabel
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#e8c061", // Change the color here
                        },
                      }}
                      type="radio"
                      name={q.id}
                      value="1"
                      checked={answers[q.id] === "1"}
                      onChange={() => handleAnswerChange(q.id, "1")}
                    />
                  }
                  label={q.statement1}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Radio
                      sx={{
                        "&.Mui-checked": {
                          color: "#e8c061", // Change the color here
                        },
                      }}
                      type="radio"
                      name={q.id}
                      value="2"
                      checked={answers[q.id] === "2"}
                      onChange={() => handleAnswerChange(q.id, "2")}
                    />
                  }
                  label={q.statement2}
                />
              </div>
            </div>
          ))}
        </div>
        <Box display="flex" alignItems="center" justifyContent="center">
          <ButtonGroup>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBack}
              disabled={page === 0}
              sx={{
                marginRight: "8px",
              }}
            >
              Anterior
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={allQuestionsAnswered &&Object.values(answers).length/chunkSize -1 === page ? calculateMBTIResult : handleNext}
              disabled={Object.values(answers).length < (page + 1) * chunkSize}
            >
              {allQuestionsAnswered && Object.values(answers).length/chunkSize -1 === page ? "Finalizar" : "Próximo"}
            </Button>
          </ButtonGroup>
        </Box>
      </form>
      {/* <Button variant="contained" color="primary" onClick={handleRandomAnswers}>
        Answer all
      </Button>
      <Button onClick={calculateMBTIResult} variant="contained" color="primary">
        Finish
      </Button> */}
      <div className="w3-bottom"></div>
      <Modal open={false} id="warning">
        <div className="w3-modal-content">
          <header className="w3-container w3-red">
            <Button
              onClick={() =>
                (document.getElementById("warning").style.display = "none")
              }
            />
            <h2>Warning</h2>
          </header>
          <Container>
            <p id="msg"></p>
          </Container>
        </div>
      </Modal>
    </>
  );
};

export default Form;
