import { AppBar, Box, Container, Toolbar, Typography } from "@mui/material";
import React from "react";
import logo from "./logo.png";
import Confetti from "react-confetti";
import { useConfetti } from "./confettiContext";

const Default = ({ children }) => {
  const { showConfetti } = useConfetti();
  return (
    <>
      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={100} // Adjust the number of confetti pieces
          gravity={3}
          wind={0.01} // Experiment with different values
        />
      )}
      <AppBar position="fixed">
        <Toolbar>
          <Container maxWidth="lg">
            <Box display="flex" alignItems="center">
              <img
                src={logo} // Replace with your logo URL
                alt="Ideasense"
                height="40" // Set the height as needed
              />
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1 }}
              ></Typography>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ paddingTop: "90px" }}>{children}</Container>
    </>
  );
};

export default Default;
