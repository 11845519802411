import React, { createContext, useContext, useState } from 'react';

// Create a context
const ConfettiContext = createContext();

// Create a provider component
export function ConfettiProvider({ children }) {
  const [showConfetti, setShowConfetti] = useState(false);

  return (
    <ConfettiContext.Provider value={{ showConfetti, setShowConfetti }}>
      {children}
    </ConfettiContext.Provider>
  );
}

// Create a custom hook to access the context
export function useConfetti() {
  return useContext(ConfettiContext);
}
