import React from "react";
import Default from "./Default";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import ResultPage from "./ResultPage";
import Form from "./Form";
import { ConfettiProvider } from "./confettiContext";

function App() {
  return (
    <ConfettiProvider>
      <Default>
        <BrowserRouter>
          <Routes>
            <Route element={<ResultPage />} path="/result/:_id"></Route>
            <Route path="/" element={<Form />}></Route>
          </Routes>
        </BrowserRouter>
      </Default>
    </ConfettiProvider>
  );
}

export default App;
