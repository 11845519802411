import {
  Box,
  Typography,
  Button,
  Modal,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import MBTI from "./mbti";
import { PatternFormat } from "react-number-format";
import axios from "axios";

const ResultPage = () => {
  const { _id } = useParams();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const handleFormSubmit = (e) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    axios
      .post(`${process.env.REACT_APP_API_URL}/save`, {
        name,
        phone,
        email,
        testType: "mbti",
        result: _id,
      })
      .then((response) => {
        setEmail("")
        setPhone("")
        setName("")
        setOpenModal(false)
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/contact/${response.data._id}`,
            {
              name,
              phone,
              email,
              testType: "mbti",
            }
          )
          .then((response) => {
            // Handle the successful response
            console.log("Response:", response.data);
          })
          .catch((error) => {
            // Handle errors
            console.error("Error:", error);
          });

        // Handle the successful response
        console.log("Response:", response.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
    console.log(apiUrl);
    e.preventDefault();
    // Handle form submission here
    // You can access the values of 'name', 'phone', and 'email' state here
  };

  // Fetch the translated text based on the _id (replace with your logic to fetch text)
  const fetchTranslatedText = (_id) => {
    // Replace this with your actual fetch logic
    // For example, fetching data from an API
    // Here's a simplified example
    return new Promise((resolve, reject) => {
      // Simulated data for demonstration
      const translatedText = {
        _id: _id,
        text: "This is the translated text for ID " + _id,
      };
      setTimeout(() => resolve(translatedText), 1000); // Simulating an asynchronous request
    });
  };

  const [translatedText, setTranslatedText] = React.useState(null);

  React.useEffect(() => {
    // Fetch the translated text when the component mounts
    fetchTranslatedText(_id)
      .then((data) => {
        setTranslatedText(data.text);
      })
      .catch((error) => {
        console.error("Error fetching translated text:", error);
      });
  }, [_id]);

  return (
    <div>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleOpenModal}>
          Compartilhar seu resulado
        </Button>
      </Box>
      <Modal open={openModal} onClose={handleOpenModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h5">
            Compartilhe seu resultado conosco
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
            />
            <PatternFormat
              customInput={TextField}
              format="(##) #####-####"
              mask="_"
              label="Phone"
              variant="outlined"
              fullWidth
              value={phone}
              onValueChange={({ value }) => setPhone(value)}
              margin="normal"
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" disabled={!email || !phone || !name}>
              Enviar
            </Button>
          </form>
        </Box>
      </Modal>
      <MBTI personality={_id} />
    </div>
  );
};

export default ResultPage;
